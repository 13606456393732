.xtl_card_icon {
    position: absolute;
    top: 0;
    right: 0;
    padding: 20px;
    width: 80px;
}

.xtl_stats_logo {
    width: 60px;
}

.xtl_navbar_service {
    width: 36px;
}

.xtl_navbar_service .bbps {
    height: 39px;
    border: 1px solid #cbcbcb;
    border-radius: 4px;
    padding: 2px;
}

.table td.fit,
.table th.fit {
    white-space: nowrap;
    width: 1%;
    /* width: auto !important; */
    table-layout: auto !important;
}

.div_form-control .form-control {
    min-height: 38px;
}

.xtl-date-picker {
    border: 1px solid #eae8f0;
    padding-left: 6px;
    border-radius: 5px;
}

.xtl_capitalize {
    text-transform: capitalize;
}

.cal_input {
    min-width: 210px;
}

.xtl_logo_img {
    /* max-width: 36px !important; */
}

.xtl_fallback_img {
    max-width: 155px !important;
}

.xtl_loader {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 9999999999;
    width: 100%;
    background-color: #fff;
}

.xtl_loader_hide {
    display: none !important;
}

.pos-rel {
    position: relative !important;
}